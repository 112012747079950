declare global {
  interface Window {
    scrollLockStack?: number[];
    scrollTopPosition?: number;
  }
}

declare global {
  interface Window {
    scrollLockStack?: number[]; // Stack to track scroll lock states
    scrollTopPosition?: number; // Stores the scroll position before disabling scroll
  }
}

function getScrollStack(): number[] {
  if (typeof window === 'undefined') return [];
  if (!window.scrollLockStack) {
    window.scrollLockStack = [];
  }
  return window.scrollLockStack;
}

export function disableBodyScroll(): void {
  if (typeof window === 'undefined' || typeof document === 'undefined') return; // Guard clause for non-browser environments

  const stack = getScrollStack();

  // Save the current scroll position only on the first lock
  if (stack.length === 0 && window.scrollTopPosition === undefined) {
    window.scrollTopPosition = window.scrollY; // Save the scroll position

    // Apply styles to lock the body scroll
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `-${window.scrollTopPosition}px`;
    document.body.style.width = '100%';
  }

  stack.push(Date.now()); // Add a timestamp to the stack to track the scroll lock
}

export function enableBodyScroll(): void {
  if (typeof window === 'undefined' || typeof document === 'undefined') return; // Guard clause for non-browser environments

  const stack = getScrollStack();
  stack.pop(); // Remove the most recent scroll lock from the stack

  // If there are no more locks, restore the body scroll
  if (stack.length === 0) {
    const scrollTop = window.scrollTopPosition ?? 0;

    // Restore the body styles to their default state
    document.body.style.overflow = 'visible';
    document.body.style.position = 'static';
    document.body.style.top = '0';
    document.body.style.width = '100%';

    window.scrollTo(0, scrollTop); // Scroll to the original position

    window.scrollTopPosition = undefined;
  }
}

export function isBodyScrollDisabled(): boolean {
  if (typeof window === 'undefined') return false;
  return (window.scrollLockStack?.length || 0) > 0;
}

